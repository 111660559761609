<template>
  <div id="bsModalNewsletter" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title text-left">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="subject" rules="required" tag="div">
                <label for="subject">Subject</label>
                <input id="subject" v-model="form.Subject" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="template" rules="required" tag="div">
                <label>Template</label>
                <editor v-model="form.Body" :model.sync="form.Body"></editor>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="status" rules="required" tag="div">
                <label for="status">Status</label>
                <select id="status" v-model="form.Active" class="form-control">
                  <option v-for="(status, index) in statusList" :key="index"
                          :value="status.id">{{ status.label }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Save Template</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "NewsletterModal",
  data() {
    return {
      statusList: [
        {
          id: 0,
          label: "Deactive",
        },
        {
          id: 1,
          label: "Active",
        },
      ],
      title: "",
      newsletter: "",
      form: {
        ID: "",
        Subject: "",
        Body: "",
        Active: "",
      },
      isLoading: false,
      confirmHook: () => {
      },
    };
  },
  methods: {
    confirm: function () {
      this.confirmHook();
      this.close();
    },
    onSubmit: function () {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const action = payload.ID !== "" ? "UpdateTemplate" : "InsertTemplate";
      const url = process.env.VUE_APP_API_URL + "/Subscribe?" + action;
      if (payload.ID === "") {
        delete payload.ID;
      }
      this.$http.post(url, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        const message = action === "InsertTemplate" ? "Template added successfully" : "Template updated successfully";
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", message, "success");
          saveNotification(message);
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      this.form = {
        ID: "",
        Subject: "",
        Body: "",
        Active: "",
      };
      this.$nextTick(() => this.$refs.observer.reset());
      $("#bsModalNewsletter").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("newsletter-modal", (args) => {
      this.title = args.title;
      this.newsletter = args.data || null;
      this.confirmHook = args.confirm;
      $("#bsModalNewsletter").modal("show");
      if (this.newsletter) {
        this.form = {
          ID: this.newsletter.ID,
          Subject: this.newsletter.Subject,
          Body: this.newsletter.Body,
          Active: this.newsletter.IsActive ? 1 : 0,
        };
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>

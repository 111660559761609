<template>
  <div v-if="!isLoading" class="box box-default">
    <div class="box-header">
      <div class="flex justify-between align-items-center">
        <h3 class="box-title nowrap" style="flex-grow: 1">Newsletters</h3>
        <a v-tooltip="{ content: 'Only one newsletter can be selected', trigger: 'click hover focus' }">
          <button class="btn btn-primary btn-sm btn-flat ml-8" :disabled="activeCount > 1" @click="mailFlash()">Mail
            Flash</button>
        </a>
        <button class="btn btn-primary btn-sm btn-flat ml-8" @click="launchNewsletterModal()">Add Newsletter</button>
      </div>
    </div>
    <div class="box-body no-padding">
      <div v-if="meta.total" class="footer">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
      <table class="table table-condensed table-striped table-borderless">
        <thead>
          <tr>
            <th class="pl-16">ID</th>
            <th>Subject</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="meta && meta.total">
            <tr v-for="(newsletter, index) in newsletters" :key="index">
              <td class="pl-16">
                <a :href="'https://cointopay.com/landing?id=' + newsletter.ID + '&email=true'" target="_blank">{{
                  newsletter.ID }}</a>
              </td>
              <td>{{ newsletter.Subject }}</td>
              <td v-html="formatStatus(newsletter.IsActive)"></td>
              <td class="action-md pr-16">
                <div>
                  <a v-tooltip="{ content: 'View Email Template', trigger: 'click hover focus' }" class="nav-action"
                    @click="launchNewsletterTemplateModal(newsletter)">
                    <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                      <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      <path
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
                      </path>
                    </svg>
                  </a>
                  <a v-tooltip="{ content: 'Update Template', trigger: 'click hover focus' }" class="nav-action"
                    @click="launchNewsletterModal(newsletter)">
                    <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                      <path
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z">
                      </path>
                    </svg>
                  </a>
                  <a v-tooltip="{ content: 'Delete Template', trigger: 'click hover focus' }" class="nav-action danger"
                    @click="deleteTemplate(newsletter, index)">
                    <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                      <path
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                      </path>
                    </svg>
                  </a>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="4">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="meta.total" class="footer">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
    </div>
    <!-- Newsletter Template Modal -->
    <newsletter-template-modal></newsletter-template-modal>
    <!-- Newsletter Modal -->
    <newsletter-modal></newsletter-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { isAuthorized, logout, saveNotification } from "@/lib/utils";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import NewsletterTemplateModal from "@/components/modals/marketing/NewsletterTemplateModal";
import NewsletterModal from "@/components/modals/marketing/NewsletterModal";

export default {
  name: "Marketing",
  components: {
    NewsletterModal,
    NewsletterTemplateModal,
    Pagination,
  },
  data() {
    return {
      keyword: "",
      newsletters: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
    ]),
    activeCount() {
      return this.newsletters.filter(newsletter => newsletter.IsActive).length;
    },
  },
  methods: {
    isAuthorized(p) {
      return isAuthorized(p);
    },
    updatePage(page) {
      this.getPaginatedNewsletters(page);
    },
    getPaginatedNewsletters(page = 1, keyword = "", isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListNewsletters`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword,
          limit: this.meta.limit,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.newsletters = response.data.data.newsletters;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatStatus(status) {
      const formattedStatus = status ? "Active" : "Deactive";
      const lblClass = status ? "label-success" : "label-danger";
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    launchNewsletterTemplateModal(newsletter) {
      this.$bus.$emit("newsletter-template-modal", {
        title: newsletter.Subject,
        data: newsletter.Body,
      });
    },
    launchNewsletterModal(newsletter = null) {
      this.$bus.$emit("newsletter-modal", {
        title: newsletter ? "Update Newsletter" : "Add Newsletter",
        data: newsletter,
        confirm: () => {
          this.getPaginatedNewsletters(1, "", false);
        },
      });
    },
    deleteTemplate(newsletter, index) {
      this.$swal.fire({
        title: "Newsletter Template",
        text: "Are you sure, you want to delete this template?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/Subscribe?DeleteTemplate`, {
            params: {
              ID: newsletter.ID,
            },
            withCredentials: true,
          }).then(response => {
            if (response.status === 200) {
              if (response.data && response.data.status_code === 400) {
                if (response.data.message.toLowerCase() === "no permission") {
                  logout();
                }
              } else {
                this.newsletters.splice(index, 1);
                this.$nextTick(() => {
                  this.meta = Object.assign(this.meta, {
                    total: this.meta.total--,
                  });
                });
                this.$toast.fire("", "Template deleted successfully", "success");
                saveNotification("Template deleted successfully");
              }
            }
          });
        }
      });
    },
    mailFlash() {
      this.$swal.fire({
        title: "Mail Flash",
        text: "Are you sure you want to send all Active Newsletters to prepared subscribers?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          // Prep
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/Subscribe?UpdateNewsletter`, {
            withCredentials: true,
          }).then(response => {
            if (response.status === 200) {
              if (response.data && response.data.status_code === 400) {
                if (response.data.message.toLowerCase() === "no permission") {
                  logout();
                }
              } else {
                // Send mail(s) to subscribers
                this.$http.get(`${apiBaseUrl}/Subscribe?mailflash`, {
                  withCredentials: true,
                }).then(response => {
                  if (response.status === 200) {
                    this.$toast.fire("", "Mail flash initiated successfully", "success");
                    saveNotification("Mail flash initiated successfully");
                  }
                }).catch(error => {
                  console.log(error);
                });
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
  },
  mounted() {
    this.getPaginatedNewsletters();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style lang="scss" scoped></style>
